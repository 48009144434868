<template>
  <v-app style=" background-color: #FAFAFA;">
    <Toolbar />
    <v-main>
      <v-container fluid grid-list-xs pt-6>
        <v-layout row wrap>
          <v-flex xs10 lg11>
            <div>
              <h3>
                <v-avatar color="#f7b733" class="mr-5">
                  <v-icon dark>mdi-remote-desktop</v-icon>
                </v-avatar>
                รายงานการรับชำระภาษีหัก ณ ที่จ่าย
              </h3>
            </div>
          </v-flex>
        </v-layout>
        <br />
        <v-form ref="form_searchdata"  lazy-validation>
          <!-- เงื่อนไขการค้นหาข้อมูล -->
          <v-layout row wrap justify-center align-center>
            <v-flex xs12 lg11 pl-4 pr-4 pb-2>
              <v-card class="rounded-lg">
                <v-card-text>
                  <h3>เงื่อนไขการค้นหา</h3>
                </v-card-text>
                <v-layout row wrap justify-center align-center pa-5>
                  <v-flex xs12 lg3 pa-4>
                    <!-- เงื่อนไขการค้นหา วันที่เอกสาร -->
                    <v-dialog
                      ref="dialogFromdate"
                      v-model="modalFromdate"
                      :return-value.sync="fromdate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          color="amber accent-4"
                          label="วันที่รับชำระ "
                          v-model="fromdate"
                          @click:prepend="modalFromdate = true"
                          prepend-icon="mdi-calendar-outline"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="fn_changeFromdate()"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalFromdate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <!-- เงื่อนไขการค้นหา วันที่รับชำระถึง-->
                  <v-flex xs12 lg3 pa-4>
                    <v-dialog
                      ref="dialogTodate"
                      v-model="modalTodate"
                      :return-value.sync="todate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          color="amber accent-4"
                          label="ถึง"
                          v-model="todate"
                          prepend-icon="mdi-calendar-outline"
                          @click:prepend="modalTodate = true"
                          v-on="on"
                          outlined
                          dense
                          clearable
                          class="my-hint-style"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        scrollable
                        locale="th"
                        color="amber accent-4"
                        @input="$refs.dialogTodate.save(todate)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click.stop="modalTodate = false"
                        >
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-account-box-outline"
                      label="ชื่อลูกค้า"
                      color="amber accent-4"
                      v-model="customer_name"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-badge-account-horizontal-outline"
                      label="Customer Tax ID"
                      color="amber accent-4"
                      v-model="customer_taxID"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="Invoice Number"
                      color="amber accent-4"
                      v-model="invoice_number_from"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-list-outline"
                      label="To"
                      color="amber accent-4"
                      v-model="invoice_number_to"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-clipboard-text-outline "
                      label="Account Number"
                      color="amber accent-4"
                      v-model="account_number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg3 pa-4>
                    <v-text-field
                      dense
                      outlined
                      prepend-icon="mdi-wallet-outline "
                      label="Air Waybill Number"
                      color="amber accent-4"
                      v-model="air_waybill"
                    ></v-text-field>
                  </v-flex>
                  <!-- ส่วนปุ่มการทำงาน -->
                  <v-flex xs12 lg12 text-right>
                    <!-- ปุ่มค้นหาข้อมูล -->
                    <v-btn
                      color="blue darken-3"
                      dark
                      class="ma-2"
                      @click="fn_getdataTotal"
                      ><b
                        ><v-icon class="mr-1">mdi-text-search</v-icon>
                        ค้นหาข้อมูล</b
                      ></v-btn
                    >
                    <!-- ปุ่ม Export Excel -->
                    <v-btn
                      color="green accent-4"
                      dark
                      class="ma-2"
                      @click="fn_exportExcel"
                      ><b
                        ><v-icon class="mr-1">mdi-file-excel-outline</v-icon>
                        Export Excel</b
                      ></v-btn
                    >
                    <!-- ปุ่ม Exportเอกสารแนบ-->
                    <v-dialog v-model="dialogExport" persistent max-width="490">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="teal darken-1" dark v-bind="attrs" v-on="on">
                          <v-icon class="mr-1" dark
                            >mdi-file-download-outline
                          </v-icon>
                          Export เอกสารแนบ
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <v-layout row wrap justify-center>
                            <v-flex xs10 lg10
                              ><span style="font-size:20px;" class="black--text"
                                >Export เอกสารแนบ ภ.ง.ด.53</span
                              >
                            </v-flex>
                            <v-flex xs1 lg1>
                             <v-icon
                             :disabled="loadingData"
                                large
                                @click="
                                  (dialogExport = false), fn_resetDataExport()
                                "
                                color="red"
                                >mdi-close-circle </v-icon
                              >
                              </v-flex
                            >
                          </v-layout>
                        </v-card-title>
                        <v-divider></v-divider>
                        <br />
                        <v-card-text>
                          <v-form
                            ref="form_exportPND"
                            lazy-validation
                          >
                            <v-layout row wrap justify-center >
                              <v-flex xs3 lg2 pa-4 text-right>
                                <div class="pt-2">เดือน</div>
                              </v-flex>
                              <v-flex xs7 lg6 pa-4>
                                <v-dialog
                                  ref="dialogPaiddatefrom"
                                  v-model="modalPaiddatefrom"
                                  :return-value.sync="paiddatefrom"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                     :disabled="loadingData"
                                      color="amber accent-4"
                                      v-model="paiddatefrom"
                                      @click:prepend="modalPaiddatefrom = true"
                                      :rules="[
                                        v => !!v || 'กรุณาเลือก เดือน'
                                      ]"
                                      v-on="on"
                                      readonly
                                      outlined
                                      dense
                                      clearable
                                      class="my-hint-style"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="paiddatefrom"
                                    scrollable
                                    locale="th"
                                    type="month"
                                    color="amber accent-4"
                                    @input="fn_changePaiddatefrom() ,fn_changeMonth() "
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click.stop="modalPaiddatefrom = false"
                                    >
                                      ปิด
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            :disabled="loadingData"
                            color="red  lighten-1"
                            outlined
                            @click="
                              (dialogExport = false), fn_resetDataExport()
                            "
                          >
                            ยกเลิก
                          </v-btn>
                          <v-btn
                            dark
                            color="green accent-4"
                            @click="fn_exportPND()"
                             :loading="loadingData"
                          >
                            ตกลง
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- ปุ่มเคลียร์ข้อมูล -->
                    <v-btn
                      color="grey darken-2"
                      dark
                      outlined
                      class="ma-2"
                      @click="fn_clearData"
                      ><b
                        ><v-icon class="mr-1">mdi-notification-clear-all</v-icon
                        >เคลียร์ข้อมูล</b
                      ></v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>

        <!-- ส่วนของข้อมูลรวมทั้งหมด รายการภาษีหัก ณ ที่จ่าย -->
        <v-layout row wrap justify-center align-center pt-2>
          <v-flex xs12 lg2 pa-3 align-self-center>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                Total
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_data }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- จำนวนเงินที่ต้องคำนวณภาษี -->
                Grand Total Amount
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_basis_amount }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- ภาษีหัก ณ ที่จ่าย -->
                Total WHT 1%
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_wht1 }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg2 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                Total WHT 3%
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_wht3 }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg3 pa-3>
            <v-card class="rounded-lg">
              <v-card-text class="text-center">
                <!-- เงินที่เข้าบัญชี -->
                Total Received Amount
              </v-card-text>
              <v-card-text class="text-center">
                <h3 class="black--text">{{ total_actual_amount }}</h3>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- <v-layout row wrap justify-center align-center>
          <v-flex xs12 lg5 pa-4
            ><v-text-field label="Search" outlined dense></v-text-field
          ></v-flex>
          <v-flex xs12 lg2 pa-4>
            <v-text-field
              label="Start Date"
              prepend-inner-icon="mdi-calendar-outline"
              outlined
              dense
            ></v-text-field>
          </v-flex>
          <v-flex xs12 lg2 pa-4>
            <v-text-field
              label="End Date"
              prepend-inner-icon="mdi-calendar-outline"
              outlined
              dense
            ></v-text-field>
          </v-flex>
        </v-layout> -->
        <!-- ส่วนของตารางข้อมูล -->
        <v-layout row wrap justify-center align-center pt-3 pb-8>
          <v-flex xs12 lg11 pl-4 pr-4>
            <v-card class="rounded-lg">
              <v-data-table
                :headers="headers"
                :items="desserts"
                sortBy="document_date"
                hide-default-footer
                :items-per-page="perpage"
                :page.sync="page"
                @page-count="pageCount = $event"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="item.number">
                      <td class="text-center" style="font-size: 13px">
                        {{ index + 1 + perpage * (page - 1) }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        <div v-if="item.paid_date == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ getDate(item.paid_date.split("T")[0]) }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="item.document_id == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.document_id }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="item.awb_no == null" class="text-center">
                          <span>-</span>
                        </div>
                        <div v-else>
                          <div v-if="item.awb_no.length == 1">
                            <span
                              v-for="(num_awb, index) in item.awb_no"
                              :key="index"
                              >{{ num_awb }}</span
                            >
                          </div>
                          <div v-else-if="item.awb_no.length >= 1">
                            <div class="text-center">-</div>
                          </div>
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="item.customer_account == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.customer_account }}
                        </div>
                      </td>
                      <td style="font-size: 13px">
                        <div v-if="item.customer_name == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.customer_name }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.basis_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.basis_amount.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.total_vat == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.total_vat.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.total_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.total_amount.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.wht3_basis == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.wht3_basis.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.wht3 == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.wht3.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.wht1_basis == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.wht1_basis.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.wht1 == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.wht1.toLocaleString() }}
                        </div>
                      </td>
                      <td style="font-size: 13px" class="text-right">
                        <div v-if="item.actual_amount == null">
                          <span>-</span>
                        </div>
                        <div v-else>
                          {{ item.actual_amount.toLocaleString() }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <br />
              <v-pagination
                :total-visible="10"
                v-model="page"
                :length="pageCount"
                color="#f7b733"
                @input="fn_getdataTotal"
                circle
              ></v-pagination>
              <br />
            </v-card>
          </v-flex>
        </v-layout>
        <!-- ส่วนของปุ่มสถานะกำลังดาวน์โหลด -->
        <v-overlay :value="loadingStatus">
          <div class="text-center">กำลังดาวน์โหลดข้อมูล ...</div>
          <v-progress-circular
            indeterminate
            size="64"
            class="ml-9 mt-3"
          ></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Toolbar from '../components/toolbar'
import Swal2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { format } from 'date-fns'
const Toast = Swal2.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})
export default {
  components: {
    Toolbar
  },
  data: function () {
    return {
      loadingData: false,
      account_number: '',
      invoice_number_from: '',
      invoice_number_to: '',
      air_waybill: '',
      total_number: '',
      total_data: '',
      total_basis_amount: '',
      total_actual_amount: '',
      total_wht1: '',
      total_wht3: '',
      customer_name: '',
      customer_taxID: '',
      page: 1,
      perpage: 50,
      fromdate: '',
      modalFromdate: false,
      todate: '',
      modalTodate: false,
      paiddatefrom: '',
      paid_date_from: '',
      paid_date_to: '',
      modalPaiddatefrom: false,
      loadingStatus: false,
      dialogExport: false,
      headers: [
        {
          text: 'ลำดับ',
          align: 'start',
          sortable: false,
          value: 'number',
          class: 'black--text'
        },
        {
          text: 'วันที่รับชำระ',
          value: 'paid_date',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'เลขที่อ้างอิง',
          value: 'document_id',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'AWB No.',
          value: 'awb_no',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'รหัสลูกค้า',
          value: 'customer_account',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'ชื่อลูกค้า',
          value: 'customer_name',
          sortable: false,
          align: 'center',
          class: 'black--text'
        },
        {
          text: 'มูลค่าก่อนคำนวณภาษี',
          value: 'basis_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี',
          value: 'total_vat',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่ารวมภาษี',
          value: 'total_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าที่นำมาคิด 3%',
          value: 'wht3_basis',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี 3%',
          value: 'wht3',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าที่นำมาคิด 1%',
          value: 'wht1_basis',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'มูลค่าภาษี 1%',
          value: 'wht1',
          sortable: false,
          align: 'right',
          class: 'black--text'
        },
        {
          text: 'จำนวนเงินได้รับจริง',
          value: 'actual_amount',
          sortable: false,
          align: 'right',
          class: 'black--text'
        }
      ],
      desserts: []
    }
  },
  computed: {
    pageCount: {
      get () {
        const l = this.total_number
        const s = this.perpage
        return Math.ceil(l / s)
      },
      set (newName) {
        return newName
      }
    },
    _fromdate: {
      get () {
        if (this.fromdate === '' || this.fromdate === null) {
          return ''
        } else {
          return format(new Date(this.fromdate), 'yyyy-MM-dd')
        }
      }
    },
    _todate: {
      get () {
        if (this.todate === '' || this.todate === null) {
          return ''
        } else {
          return format(new Date(this.todate), 'yyyy-MM-dd')
        }
      }
    }
  },
  created () {
    // set defult ใน v-model วันที่รับชำระ
    // this.fromdate = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)
    //   .toISOString()
    //   .substr(0, 10)
    // this.todate = new Date(Date.now()).toISOString().substr(0, 10)
    // เซ็ตค่าวันที่รับเอกสาร Export PND.53
    this.paiddatefrom = new Date(Date.now()).toISOString().substr(0, 7)
  },
  mounted () {
    this.fn_getdataTotal()
    this.fn_changeMonth()
  },
  methods: {
    // ฟังชันเปลี่ยนเเปลงค่า วันที่เริ่มต้นจ่าย
    fn_changeFromdate () {
      this.$refs.dialogFromdate.save(this.fromdate)
    },
    fn_changePaiddatefrom () {
      this.$refs.dialogPaiddatefrom.save(this.paiddatefrom)
    },
    fn_selectMonth (month, year) {
      this.paid_date_from = year + '-' + month + '-01'
      var dayEnd = new Date(parseInt(year), parseInt(month), 0).getDate()
      this.paid_date_to = year + '-' + month + '-' + dayEnd
    },
    fn_changeMonth () {
      var selectmonth = this.paiddatefrom.split('-')
      this.fn_selectMonth(selectmonth[1], selectmonth[0])
    },
    // ฟังก์ชัน format วันที
    getDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // ฟังก์ชันค้นข้อมูลทั้งหมด Fix
    async fn_getdataTotal () {
      if (this.$refs.form_searchdata.validate()) {
        const payload = {
          customer_tax_id: this.customer_taxID,
          customername: this.customer_name,
          paid_date_from: this._fromdate,
          paid_date_to: this._todate,
          page: this.page,
          perpage: this.perpage,
          customer_account: this.account_number,
          document_id_from: this.invoice_number_from,
          document_id_to: this.invoice_number_to,
          awb_no: this.air_waybill
        }
        // console.log(payload)
        this.loadingStatus = true
        const getAccesstoken = await this.$store.dispatch('action_request_accesstoken')
        if (getAccesstoken.status === 'ER') {
          Toast.fire({
            icon: 'error',
            title: 'Session Timeout'
          })
          this.$router.push('/logout')
        } else {
          var headers = { Authorization: getAccesstoken.token }
          // var headers = { Authorization: '' + AccessToken }
          this.axios
            .post(process.env.VUE_APP_SERVICE_API + '/witholdingtax', payload, { headers })
            .then(res => {
              if (res.data.status === 'OK' && res.data.data.length > 0) {
                this.desserts = res.data.data
                this.total_number = res.data.total_data

                // ค่าผลรวม Total
                if (res.data.total_data == null) {
                  this.total_data = '-'
                  console.log('Total : null')
                } else {
                  this.total_data = parseInt(
                    res.data.total_data
                  ).toLocaleString()
                }

                // ค่า Grand Total Amount
                if (res.data.total_basis_amount == null) {
                  this.total_basis_amount = '-'
                  console.log(' Grand Total Amount: null')
                } else {
                  this.total_basis_amount = res.data.total_basis_amount.toLocaleString(
                    'en-US',
                    { maximumFractionDigits: 2 }
                  )
                }

                // ค่า Total WHT 1%
                if (res.data.total_wht1 == null) {
                  this.total_wht1 = '-'
                  console.log('Total WHT 1% : null')
                } else {
                  this.total_wht1 = res.data.total_wht1.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                }

                // ค่า Total WHT 3%
                if (res.data.total_wht3 == null) {
                  this.total_wht3 = '-'
                  console.log('Total WHT 3% : null')
                } else {
                  this.total_wht3 = res.data.total_wht3.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                }

                // ค่า Total Received Amount
                if (res.data.total_actual_amount == null) {
                  this.total_actual_amount = '-'
                  console.log('Total Received Amount : null')
                } else {
                  this.total_actual_amount = res.data.total_actual_amount.toLocaleString(
                    'en-US',
                    { maximumFractionDigits: 2 }
                  )
                }

                // console.log(this.desserts)
                this.loadingStatus = false
              } else if (res.data.data.length === 0) {
                this.loadingStatus = false
                Toast.fire({
                  icon: 'warning',
                  title: 'ไม่พบข้อมูลในระบบ'
                })
              }
            })
            .catch(err => {
              this.loadingStatus = false

              console.log(err)
              Toast.fire({
                icon: 'error',
                title: String(err)
              })
            })
        }
      } else {
        this.fn_clearData()
        this.desserts = []
        this.loadingStatus = false
      }
      // this.fn_clearData()
    },
    // ฟังก์ชัน Export excel fix
    async fn_exportExcel () {
      if (this.$refs.form_searchdata.validate()) {
        const payload = {
          customer_tax_id: this.customer_taxID,
          customername: this.customer_name,
          paid_date_from: this._fromdate,
          paid_date_to: this._todate,
          customer_account: this.account_number,
          document_id_from: this.invoice_number_from,
          document_id_to: this.invoice_number_to,
          awb_no: this.air_waybill
        }
        // console.log('Excel', payload)
        this.loadingStatus = true
        const getAccesstoken = await this.$store.dispatch('action_request_accesstoken')
        if (getAccesstoken.status === 'ER') {
          Toast.fire({
            icon: 'error',
            title: 'Session Timeout'
          })
          this.$router.push('/logout')
        } else {
          var headers = { Authorization: getAccesstoken.token }
          this.axios({
            method: 'post',
            url: process.env.VUE_APP_SERVICE_API + '/witholdingtax/exportxlsx',
            data: payload,
            headers: headers,
            responseType: 'arraybuffer'
          })
            .then(res => {
              console.log(res)
              if (res.status === 200) {
                var blob = new Blob([res.data], {
                  type: 'application/xlsx'
                })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'รายงานการรับชำระภาษีหัก ณ ที่จ่าย.xlsx'
                link.click()

                this.loadingStatus = false
              } else {
                this.loadingStatus = false
                Toast.fire({
                  icon: 'warning',
                  title: 'ไม่พบข้อมูลในระบบ'
                })
              }
            })
            .catch(err => {
              this.loadingStatus = false
              console.log(err)
              Toast.fire({
                icon: 'error',
                title: String(err)
              })
            })
        }
      } else {
        this.fn_clearData()
        this.desserts = []
        this.loadingStatus = false
      }
    },
    // funtion export PDN 53 fix
    async fn_exportPND () {
      if (this.$refs.form_exportPND.validate()) {
        const payload = {
          paid_date_from: this.paid_date_from,
          paid_date_to: this.paid_date_to
        }
        // console.log(payload)
        const getAccesstoken = await this.$store.dispatch('action_request_accesstoken')
        if (getAccesstoken.status === 'OK') {
          var headers = { Authorization: getAccesstoken.token }
          this.dialogExport = true
          this.loadingData = true
          this.axios({
            method: 'post',
            url: process.env.VUE_APP_SERVICE_API + '/witholdingtax/exportincometaxreturn',
            data: payload,
            headers: headers,
            responseType: 'blob'
          }).then(res => {
            console.log(res)
            if (res.status === 200) {
              this.dialogExport = false
              this.loadingStatus = false
              this.loadingData = false
              const url = URL.createObjectURL(
                new Blob([res.data], {
                  type: 'application/vnd.ms-excel'
                })
              )
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', payload)
              document.body.appendChild(link)
              link.download = 'เอกสารแนบ ภ.ง.ด.53.xlsx'
              link.click()
              this.fn_resetDataExport()
            } else {
              this.dialogExport = false
              this.loadingData = false

              Toast.fire({
                icon: 'error',
                title: 'ไม่พบไฟล์เอกสารในระบบฐานข้อมูล'
              })
            }
          })
            .catch(err => {
              console.log(err)
              this.dialogExport = false
              this.loadingData = false

              Toast.fire({
                icon: 'error',
                title: 'ไม่สามารถเชื่อมต่อ API ดาวน์โหลดไฟล์ ได้'
              })
            })
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Session Timeout'
          })
          this.$router.push('/logout')
        }
      }
    },
    // ฟังก์ชันรีเซตค่า Validate
    fn_resetDataExport: function () {
      this.$refs.form_exportPND.resetValidation()
      this.paiddatefrom = new Date(Date.now()).toISOString().substr(0, 7)
    },
    // ฟังก์ชันเคลียร์ข้อมูล
    fn_clearData: function () {
      this.account_number = ''
      this.invoice_number_from = ''
      this.invoice_number_to = ''
      this.air_waybill = ''
      this.total_number = ''
      this.total_data = ''
      this.total_basis_amount = ''
      this.total_actual_amount = ''
      this.total_wht1 = ''
      this.total_wht3 = ''
      this.fromdate = ''
      this.todate = ''
      this.customer_name = ''
      this.desserts = []
      this.paiddatefrom = ''
    }
  }
}
</script>
